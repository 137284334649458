import { createRouter, createWebHistory } from 'vue-router'

import admin from './middleware/admin'
import user from './middleware/user'

import middlewarePipeline from './pipeline'

const routes = [
  {
    path: '/',
    redirect: () => 'my-company'
  },
  {
    path: '/profile',
    component: () => import('@/views/Profile')
  },
  {
    path: '/my-company',
    component: () => import('@/views/companies/detail/Index'),
    children: [
      {
        path: '',
        component: () => import('@/views/companies/detail/instances/Main')
      },
      {
        path: 'address',
        component: () => import('@/views/companies/detail/instances/Address')
      },
      {
        path: 'contacts',
        component: () => import('@/views/companies/detail/instances/Contacts')
      },
      {
        path: 'diplomas',
        component: () => import('@/views/companies/detail/instances/Diplomas')
      },
      {
        path: 'projects',
        component: () => import('@/views/companies/detail/instances/Projects')
      },
      {
        path: 'payments',
        component: () => import('@/views/companies/detail/instances/Payments')
      }
    ],
    meta: {
      isOwner: true
    }
  },
  {
    path: '/my-company/projects/:id(\\d+)',
    component: () => import('@/views/projects/Edit'),
    meta: {
      isOwner: true
    }
  },
  {
    path: '/my-company/projects/add',
    component: () => import('@/views/projects/Add'),
    meta: {
      isOwner: true
    }
  },
  {
    path: '/advertising',
    component: () => import('@/views/advertising/Index'),
    children: [
      {
        path: '',
        component: () => import('@/views/advertising/instances/All')
      },
      {
        path: 'purchases',
        component: () => import('@/views/advertising/instances/Purchases')
      },
      {
        path: 'history',
        component: () => import('@/views/advertising/instances/History')
      }
    ],
    meta: {
      isOwner: true
    }
  },
  {
    path: '/advertising/general',
    component: () => import('@/views/advertising/General'),
    meta: {
      isOwner: true
    }
  },
  {
    path: '/support',
    component: () => import('@/views/support/Index'),
    meta: {
      middleware: [user]
    }
  },
  {
    path: '/support/:id(\\d+)',
    component: () => import('@/views/support/Detail'),
    meta: {
      middleware: [user]
    }
  },
  {
    path: '/companies',
    component: () => import('@/views/companies/Index'),
    meta: {
      middleware: [admin]
    }
  },
  {
    path: '/companies/:id(\\d+)',
    component: () => import('@/views/companies/detail/Index'),
    children: [
      {
        path: '',
        component: () => import('@/views/companies/detail/instances/Main')
      },
      {
        path: 'address',
        component: () => import('@/views/companies/detail/instances/Address')
      },
      {
        path: 'contacts',
        component: () => import('@/views/companies/detail/instances/Contacts')
      },
      {
        path: 'diplomas',
        component: () => import('@/views/companies/detail/instances/Diplomas')
      },
      {
        path: 'projects',
        component: () => import('@/views/companies/detail/instances/Projects')
      },
      {
        path: 'advertising',
        component: () => import('@/views/companies/detail/instances/Advertising'),
        children: [
          {
            path: '',
            component: () => import('@/views/advertising/instances/All')
          },
          {
            path: 'purchases',
            component: () => import('@/views/advertising/instances/Purchases')
          },
          {
            path: 'history',
            component: () => import('@/views/advertising/instances/History')
          }
        ]
      },
      {
        path: 'payments',
        component: () => import('@/views/companies/detail/instances/Payments')
      }
    ],
    meta: {
      middleware: [admin],
      isOwner: false
    }
  },
  {
    path: '/companies/:id(\\d+)/advertising/general',
    component: () => import('@/views/advertising/General'),
    meta: {
      middleware: [admin],
      isOwner: false
    }
  },
  {
    path: '/companies/:id(\\d+)/projects/:projectId(\\d+)',
    component: () => import('@/views/projects/Edit'),
    meta: {
      middleware: [admin]
    }
  },
  {
    path: '/companies/:id(\\d+)/projects/add',
    component: () => import('@/views/projects/Add'),
    meta: {
      middleware: [admin]
    }
  },
  {
    path: '/projects',
    component: () => import('@/views/projects/Index'),
    meta: {
      middleware: [admin]
    }
  },
  {
    path: '/projects/:id(\\d+)',
    component: () => import('@/views/projects/Edit'),
    meta: {
      isModeration: true,
      middleware: [admin]
    }
  },
  {
    path: '/reviews',
    component: () => import('@/views/Reviews'),
    meta: {
      middleware: [admin]
    }
  },
  {
    path: '/outstaff',
    component: () => import('@/views/outstaff/Index')
  },
  {
    path: '/outstaff/add',
    component: () => import('@/views/outstaff/Add')
  },
  {
    path: '/outstaff/:id(\\d+)',
    component: () => import('@/views/outstaff/Edit')
  },
  {
    path: '/mailing',
    component: () => import('@/views/mailing/Index'),
    children: [
      {
        path: '',
        component: () => import('@/views/mailing/instances/Mailing')
      },
      {
        path: 'history',
        component: () => import('@/views/mailing/instances/History')
      },
      {
        path: 'unsubscribed',
        component: () => import('@/views/mailing/instances/Unsubscribed')
      }
    ],
    meta: {
      middleware: [admin]
    }
  },
  {
    path: '/subscriptions',
    component: () => import('@/views/Subscriptions'),
    meta: {
      middleware: [admin]
    }
  },
  // {
  //   path: '/promocodes',
  //   component: () => import('@/views/Promocodes'),
  //   meta: {
  //     middleware: [admin]
  //   }
  // },
  {
    path: '/articles',
    component: () => import('@/views/articles/Index'),
    meta: {
      middleware: [admin]
    }
  },
  {
    path: '/articles/add',
    component: () => import('@/views/articles/Add'),
    meta: {
      middleware: [admin]
    }
  },
  {
    path: '/articles/:id(\\d+)',
    component: () => import('@/views/articles/Edit'),
    meta: {
      middleware: [admin]
    }
  },
  {
    path: '/admin-support',
    component: () => import('@/views/support/Admin'),
    children: [
      {
        path: '',
        component: () => import('@/views/support/instances/Inbox')
      },
      {
        path: 'archive',
        component: () => import('@/views/support/instances/Archive')
      }
    ],
    meta: {
      middleware: [admin]
    }
  },
  {
    path: '/admin-support/:id(\\d+)',
    component: () => import('@/views/support/Detail'),
    meta: {
      middleware: [admin]
    }
  },
  {
    path: '/purchases',
    component: () => import('@/views/purchases/Index'),
    children: [
      {
        path: '',
        component: () => import('@/views/purchases/instances/Moderation')
      },
      {
        path: 'history',
        component: () => import('@/views/purchases/instances/History')
      }
    ],
    meta: {
      middleware: [admin]
    }
  },
  {
    path: '/payment/success',
    component: () => import('@/views/payment/Success')
  },
  {
    path: '/payment/fail',
    component: () => import('@/views/payment/Fail')
  },
  {
    name: 'Error',
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/Error')
  }
]

export default (app) => {
  const store = app.config.globalProperties.$store

  const router = createRouter({
    history: createWebHistory(),
    routes,
    strict: true
  })

  router.beforeEach(async (to, from, next) => {
    const body = document.body

    body.classList.remove('modal-open')
    body.querySelector('.modal-backdrop')?.remove()

    if (!store.state.auth.loggedIn) {
      window.location.replace(`${process.env.VUE_APP_SITE_URL}/login`)
    }

    if (!to.meta.middleware) {
      return next()
    }

    const middleware = to.meta.middleware

    const context = {
      to,
      from,
      next,
      store,
      push: router.push,
      replace: router.replace
    }

    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
    })
  })

  return router
}
